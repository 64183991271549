import {
  AbstractChainId,
  BlockchainAsset,
  BlockchainId,
  BlockchainMethod,
} from 'src/types'

/**
 * Default asset must be always present in every Polity wallet (added automatically upon creation, and can't be removed).
 * (if it's not, it must be a backend bug).
 *
 * In pair with default chain, it's used in "switch protocol" WalletConnect flow.
 * @see https://dcspoc.atlassian.net/browse/PLT-403?focusedCommentId=10560
 */
export const DEFAULT_WALLET_ASSET = BlockchainAsset.Ethereum
export const DEFAULT_WALLET_CHAIN = BlockchainId.Ethereum

const AssetsByChains: Partial<Record<BlockchainId, BlockchainAsset[]>> = {
  [BlockchainId.Ethereum]: [BlockchainAsset.Ethereum],
  [BlockchainId.Polygon]: [BlockchainAsset.Polygon],
  [BlockchainId.Optimism]: [],
  [BlockchainId.Solana]: [],
}

export function getChainSupportedAssets(
  chainId: AbstractChainId
): BlockchainAsset[] {
  return AssetsByChains[chainId as BlockchainId] ?? []
}

export function isChainSupportsAsset(
  chainId: AbstractChainId,
  asset: BlockchainAsset
): boolean {
  return getChainSupportedAssets(chainId).includes(asset)
}

export function getChainName(chainId: AbstractChainId): string {
  switch (chainId) {
    case BlockchainId.Ethereum:
      return 'Ethereum'
    case BlockchainId.Polygon:
      return 'Polygon'
    case BlockchainId.Optimism:
      return 'Optimism'
    case BlockchainId.Solana:
      return 'Solana'
    case BlockchainId.Avocado:
      return 'Avocado'
    default:
      return `id=${chainId} (unknown)`
  }
}

const BlockChainMethodsList = Object.values(BlockchainMethod)

export function isSupportedBlockchainMethod(x: string): x is BlockchainMethod {
  return BlockChainMethodsList.includes(x as BlockchainMethod)
}

export function getAssetFullName(asset: BlockchainAsset): string | undefined {
  switch (asset) {
    case BlockchainAsset.Ethereum:
      return 'Ethereum'
    case BlockchainAsset.EthereumClassic:
      return 'Ethereum Classic'
    case BlockchainAsset.Bitcoin:
      return 'Bitcoin'
    case BlockchainAsset.BitcoinCash:
      return 'Bitcoin Cash'
    case BlockchainAsset.USDC:
      return 'USD Coin'
    case BlockchainAsset.Polygon:
      return 'Polygon'
    case BlockchainAsset.Tron:
      return 'Tron'
    case BlockchainAsset.Dash:
      return 'Dash'
    case BlockchainAsset.Avalanche:
      return 'Avalanche'
    case BlockchainAsset.Heco:
      return 'Heco'

    // TODO: these ones look weird, can't find them in crypto explorers
    // case BlockchainAsset.USDC_ETH:
    // case BlockchainAsset.USDC_ERC20:

    default:
      return undefined
  }
}

// TODO: for production, probably should download full icons pack locally
export function getAssetIconURI(asset: BlockchainAsset): string | undefined {
  switch (asset) {
    // cryptologos.cc doesn't have this one
    case BlockchainAsset.Heco:
      return 'https://icoholder.com/files/img/90bf4dc5d881de1e94017b407b0ad8d0.jpeg'
    case BlockchainAsset.Polygon:
      return 'https://cryptologos.cc/logos/polygon-matic-logo.svg'

    default: {
      const fullName = getAssetFullName(asset)
      if (fullName === undefined) return undefined
      const formatted = fullName.replace(/\s/g, '-')
      const filename = `${formatted}-${asset}-logo.svg`.toLowerCase()
      return `https://cryptologos.cc/logos/${filename}`
    }
  }
}
